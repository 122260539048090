<template>
  <div class="hidden border-b border-b-gray-100 bg-white md:block">
    <VuepalAdminToolbar :key="language" />
    <div class="flex overflow-auto w-full">
      <div class="py-16 mx-auto flex px-16 w-full justify-between items-center">
        <VuepalLocalTasks />
        <div class="flex gap-24 items-center whitespace-nowrap">
          <div v-if="environment !== 'production'">
            <a :href="prodUrl" target="_blank" class="link"
              >Auf PROD anzeigen</a
            >
          </div>
          <button class="link" @click="toggleTextsDebug">
            {{ isTextsDebug ? 'Texte anzeigen' : 'Keys anzeigen' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const language = useCurrentLanguage()
const route = useRoute()
const environment = useRuntimeConfig().public.environment

const { toggleDebug: toggleTextsDebug, isDebug: isTextsDebug } = useEasyTexts()

const prodUrl = computed(() => {
  return 'https://www.publica.ch' + route.path
})
</script>

<style lang="postcss">
.adminbar-colors {
  @apply flex gap-12;
  button {
    @apply h-24 w-24 rounded-full border border-gray-400;
  }
}
[data-route-name='publica_backend.overview'] {
  .vuepal-admin-toolbar-icon {
    mask-image: url(/modules/custom/publica_backend/icons/publica-toolbar.svg) !important;
  }
}

.vuepal-local-tasks {
  a {
    white-space: nowrap;
  }
}

.vuepal-admin-toolbar > ul > li:not(:first-child) {
  .vuepal-admin-toolbar-link {
    padding-left: 10px;
    padding-right: 10px;

    @screen lg {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
</style>

<template>
  <li class="navigation-first-level" :class="classes">
    <a
      ref="button"
      class="navigation-first-level-button"
      :class="{
        'is-active': isOpen,
        'has-second-level-menu': hasSecondLevelMenu,
      }"
      :href="link.url ? link.url.path : ''"
      @click.prevent="onClick"
    >
      <span :data-title="link.label">{{ link.label }}</span>
      <template v-if="hasSecondLevelMenu">
        <SpriteSymbol class="hidden md:block" name="nav-arrow-down" />
        <div
          class="flex size-28 items-center justify-center rounded-full text-white transition md:hidden"
          :class="{ 'rotate-45 bg-white !text-blue-900': isOpen }"
        >
          <SpriteSymbol name="plus" class="size-20" />
        </div>
      </template>
      <div
        v-else
        class="flex size-28 items-center justify-center rounded-full text-white transition md:hidden"
      >
        <SpriteSymbol name="arrow-right" class="size-20" />
      </div>
    </a>
    <template v-if="hasSecondLevelMenu">
      <VuepalTransitionHeight
        :duration="transitionDuration"
        @after-enter="onMobileTransitionFinished"
      >
        <div
          v-show="isOpen"
          class="navigation-first-level-content"
          :class="{ 'is-active': isOpen }"
        >
          <div class="navigation-first-level-content-inner">
            <div class="navigation-first-level-title text-2xl hidden md:block">
              <nuxt-link
                :to="link.url ? link.url.path : '/'"
                class="cursor-default font-bold text-gray-900"
              >
                {{ link.label }}
              </nuxt-link>
            </div>
            <ul>
              <NavigationSecondLevel
                v-for="(item, i) in links"
                :key="'second_' + i"
                v-bind="item"
              />
              <ClientOnly>
                <MyPensionFund v-if="isMyPension" />
              </ClientOnly>
            </ul>
          </div>
        </div>
      </VuepalTransitionHeight>
    </template>
  </li>
</template>

<script lang="ts" setup>
import MyPensionFund from './MyPensionFund.vue'
import type { MainMenuLinkTreeFirstFragment } from '#graphql-operations'
import { StaticMenuLink } from '#graphql-operations'
import { VuepalTransitionHeight } from '#components'

const button = ref<HTMLAnchorElement | null>(null)

const props = defineProps<{
  link: MainMenuLinkTreeFirstFragment['link']
  links: MainMenuLinkTreeFirstFragment['links']
  hasTransition: boolean
  isOpen: boolean
}>()

const emit = defineEmits(['toggle', 'close'])
const { isMobile } = useViewport()

const hasSecondLevelMenu = computed(() => {
  return props.link.expanded && props.links && props.links.length > 0
})
const transitionDuration = computed(() => {
  return isMobile.value || props.hasTransition ? 300 : 0
})
const classes = computed<Array<Record<string, any> | string>>(() => {
  const classes: Array<Record<string, any> | string> = [
    { 'is-active': props.isOpen },
  ]
  if (props.link.staticMenuLink) {
    classes.push('is-type-' + props.link.staticMenuLink.toLowerCase())
  }
  return classes
})

const isMyPension = computed(() => {
  return props.link.staticMenuLink === StaticMenuLink.MY_PENSION
})

watch(
  () => props.isOpen,
  (isOpen) => {
    if (isOpen) {
      document.addEventListener('keyup', onKeyUp)
    } else {
      document.removeEventListener('keyup', onKeyUp)
    }
  },
)
/**
 * On mobile, when the first level's content appear transition finished,
 * it can happen that only the lower part of it are visible in the
 * viewport. This happens when previously another first level item was
 * open and was now closed.
 * We check if this first level link is outside the viewport by a defined
 * amount of pixels. If that is the case, we scroll it into view.
 */
function onMobileTransitionFinished(): void {
  if (!isMobile.value) {
    return
  }

  // Wrap it in a try/catch because this is secondary functionality.
  try {
    if (!button.value) {
      return
    }
    const { y } = button.value.getBoundingClientRect()
    if (y < 40) {
      button.value.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  } catch (_e) {}
}

const router = useRouter()

function onClick(): void {
  if (hasSecondLevelMenu.value) {
    emit('toggle')
    return
  }
  if (props.link.url?.path) {
    emit('close')
    router.push(props.link.url?.path)
  }
}
function handleEscape(): void {
  emit('close')
  if (button.value) {
    button.value.focus()
  }
}

function onKeyUp(e: KeyboardEvent): void {
  if (e.key === 'Escape') {
    e.preventDefault()
    handleEscape()
  }
}
</script>

<style lang="postcss">
.navigation-first-level {
  @media (max-width: theme('screens.md')) {
    @apply border-b border-b-white/20;
    transition: 0.5s;
    &.is-active {
      @apply bg-blue-900;
    }
    &:first-child {
      @apply border-t border-t-white/20;
    }
  }
  .navigation-first-level-button {
    @apply font-condensed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.75rem;
    position: relative;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    white-space: nowrap;
    @screen md {
      height: var(--navigation-height);
      justify-content: center;

      @apply text-gray-900;
      span {
        z-index: 200000;
      }
      &.has-second-level-menu {
        svg {
          @apply relative h-[1em] w-[1em];
          z-index: 1000000000;
          margin-left: 0.25em;
        }
        &:after {
          /* @TODO */
          /*
          content: $i-nav-arrow-down;
          font-family: 'publica-icons' !important;
          font-weight: normal;
          font-size: 1.25rem;
          position: relative;
          */
        }
        &.is-active:after {
          transform: rotate(180deg);
        }
      }
      &:before {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
        transform-origin: bottom;
        z-index: 10000;
        opacity: 0;
        @apply border-x;
      }
      &:focus {
        outline: none;
      }
      &:hover {
        @apply text-blue-400;
      }
      &.is-active {
        &:before {
          opacity: 1;
        }
      }
    }
    @screen lg {
      @apply text-lg;
      padding: 0 1.5rem;
    }

    @media (max-width: theme('screens.md')) {
      transition: 0.5s;
      color: white;
      @apply text-2xl;
      padding: 1.25rem 1.5rem;
      &:after {
        /* @TODO */
        /* content: $i-arrow-right; */
        font-family: 'publica-icons' !important;
        font-weight: normal;
        font-size: 1.5rem;
        position: absolute;
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);
        width: 1.75rem;
        height: 1.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        transition: 0.5s;
        color: white;
      }
      &.has-second-level-menu:after {
        /* content: $i-plus; */
        /* font-size: 1.125rem; */
      }
      &.is-active {
        &:after {
          @apply bg-blue-900;
          background: white;
          transform: translateY(-50%) rotate(45deg);
        }
      }
    }

    @screen md {
      span {
        position: relative;
        display: inline-block;
      }
      /**
     * On hover the text should become bold. In order to prevent horizontal
     * layout shift due to changing box width, the hovered state is displayed
     * via this pseudo element.
     */
      span:before {
        content: attr(data-title);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: inherit;
        position: absolute;
        border-radius: 10rem;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        height: 100%;
        background: white;
        padding: inherit;
        pointer-events: none;
        user-select: none;
        opacity: 0;
        transition: 0.1s;
        letter-spacing: math.div(-0.5px, 16px) * 1em;
        white-space: nowrap;
        @apply font-bold text-blue-700;
      }
      &.is-active,
      &:focus {
        span:before {
          opacity: 1;
        }
      }
    }
  }
}

.navigation-first-level-content {
  --navigation-content-padding: 1rem;
  &.expand-enter-active,
  &.expand-leave-active {
    transition-timing-function: cubic-bezier(0.37, 0.22, 0.53, 0.88);
    overflow: hidden;
  }

  &.expand-enter,
  &.expand-leave-to {
    height: 0;
  }
  @media (max-width: theme('screens.md')) {
    > ul {
      padding: 0 1.5rem 1rem;
    }
  }
  @screen md {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: white;
    @apply rounded border-t;
    box-shadow:
      0px 20px 25px -5px rgba(0, 0, 0, 0.1),
      0px 10px 10px -5px rgba(0, 0, 0, 0.04);

    border-top-left-radius: 0;
    border-top-right-radius: 0;
    --navigation-content-padding: 1.5rem;
    --navigation-content-addon-width: 20rem;
  }
  @screen lg {
    --navigation-content-padding: 2rem;
    --navigation-content-addon-width: 25rem;
  }
  @screen xl {
    --navigation-content-padding: 3rem;
    --navigation-content-addon-width: 28rem;
  }
}

.navigation-first-level-content-inner {
  @media (max-width: theme('screens.md')) {
    padding: 1rem 1.5rem;
  }
  @screen md {
    > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > li {
        padding: var(--navigation-content-padding);
      }
    }
  }
}

.first-level-enter-active,
.first-level-leave-active {
  transition: 0.2s;
  transform-origin: top;
  @screen md {
    .navigation-first-level-content-inner {
      transition: inherit;
    }
  }
}
.first-level-enter,
.first-level-leave-to {
  opacity: 0;
  @screen md {
    transform: scaleY(0.9);
    .navigation-first-level-content-inner {
      transform: translateY(-2rem);
    }
  }
}

@screen md {
  .navigation-first-level.is-type-my_pension {
    .navigation-first-level-content-inner {
      > ul {
        > li {
          &:first-child {
            flex: 1;
            > ul {
              display: grid;
              grid-template-columns: 1fr 1fr;
            }
          }
        }
      }
    }
  }
  .navigation-first-level.is-type-about {
    .navigation-first-level-content-inner {
      > ul {
        > li {
          flex: 1;
          &:last-child {
            @apply border-t bg-gray-50;
            flex: 0 0 100%;
            > ul {
              grid-template-columns: repeat(5, 1fr);
            }
          }
        }
      }
    }
  }

  .navigation-first-level.is-type-investment {
    .navigation-first-level-content-inner {
      > ul {
        > li {
          &:first-child {
            grid-column: span 3;
            > ul {
              grid-template-columns: 1fr 1fr;
            }
          }
          &:last-child {
            @apply border border-green-100 bg-green-50;
            flex: 0 0 var(--navigation-content-addon-width);
          }
        }
      }
    }
  }

  .navigation-first-level.is-type-employers {
    margin-right: auto;
  }
  .navigation-first-level.is-type-contact {
    margin-left: auto;
  }
}

.navigation-first-level-title {
  padding: var(--navigation-content-padding);
  padding-bottom: 0;
}
</style>

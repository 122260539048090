<template>
  <footer class="footer mt-96">
    <div class="footer-top">
      <NuxtLink class="hidden md:block" :to="{ name: 'home' }">
        <SpriteSymbol name="logo-small" class="mx-auto size-50" />
      </NuxtLink>
      <ul class="footer-menu">
        <li v-for="(link, i) in topLinks" :key="'top_' + i">
          <nuxt-link :to="link.link.url?.path">{{ link.link.label }}</nuxt-link>
        </li>
      </ul>

      <NuxtLink
        v-if="myPublicaLink"
        class="button is-primary justify-center"
        :to="myPublicaLink.url?.path"
        >{{ myPublicaLink.title }}</NuxtLink
      >
      <div class="footer-address">
        <div>
          {{ $texts('companyName', 'Pensionskasse des Bundes Publica') }}
        </div>
        <div>
          {{ $texts('footerAddress', 'Eigerstrasse 57, CH–3007 Bern') }}
        </div>
      </div>
      <ul class="footer-contact">
        <li v-if="phoneLink">
          <a
            :href="phoneLink.to"
            class="button is-primary is-bubble"
            :title="phoneLink.title"
          >
            <SpriteSymbol name="phone" />
          </a>
        </li>
        <li v-if="mailLink">
          <a
            :href="mailLink.to"
            class="button is-primary is-bubble"
            :title="mailLink.title"
          >
            <SpriteSymbol name="mail" />
          </a>
        </li>
        <li v-if="linkedInLink">
          <a
            :href="linkedInLink.to"
            class="button is-primary is-bubble"
            :title="linkedInLink.title"
            target="_blank"
          >
            <SpriteSymbol name="linkedin" />
          </a>
        </li>
      </ul>
    </div>
    <ul class="footer-bottom">
      <li v-for="(link, i) in bottomLinks" :key="i">
        <nuxt-link :to="link.to">{{ link.title }}</nuxt-link>
      </li>
    </ul>
  </footer>
</template>

<script lang="ts" setup>
import { nonNullable } from '#vuepal/helpers/type'
import type { LinkItem } from '~/types/global'

const data = await useInitData()
const { $texts } = useNuxtApp()

const bottomLinks = computed(() => {
  const items = [
    data.value.siteConfig.pageImprint,
    data.value.siteConfig.pageTerms,
    data.value.siteConfig.pagePrivacyPolicy,
  ].filter(nonNullable)
  return items.map((v) => {
    return {
      to: v.url?.path || '',
      title: v.title || '',
    }
  })
})

const topLinks = computed(() => data.value.footerMenuLinks)

const phoneLink = computed<LinkItem>(() => {
  const phone = data.value.siteConfig.contactPhone
  return {
    title: $texts('phone', 'Phone'),
    to: 'tel:' + phone,
  }
})

const mailLink = computed<LinkItem>(() => {
  const email = data.value.siteConfig.contactEmail
  return {
    title: $texts('email', 'Email'),
    to: 'mailto:' + email,
  }
})

const linkedInLink = computed<LinkItem>(() => {
  const linkedInUrl = data.value.siteConfig.contactLinkedIn?.uri?.path
  return {
    title: $texts('linkedin', 'LinkedIn'),
    to: linkedInUrl,
  }
})

const myPublicaLink = computed(() => {
  return data.value.siteConfig.pageMypublica
})
</script>

<style lang="postcss">
.footer {
  @apply text-center text-white;
  --outline-color: white;
}

.footer-top {
  @apply grid bg-blue-800 px-16 py-40;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-gap: 2rem 1rem;
  > svg {
    margin: 0 auto;
  }
  @apply md:py-50 lg:py-80;
  @screen md {
    grid-template-columns: auto;
    justify-content: center;
  }

  @apply mobile-only:pt-0;

  @media (max-width: theme('screens.md')) {
    padding-top: 0;
    > .button {
      grid-row: 2;
      border-radius: 10rem;
      /* grid-column: 2; */
    }
    .footer-address {
      grid-column: 1 / 3;
    }
    .footer-contact {
      grid-row: 2;
    }

    .footer-menu {
      grid-column: 1 / 3;
      grid-row: 1;
      text-align: left;
      @apply -mx-16;
      li {
        @apply border-b border-b-blue-600;
      }
    }
  }
}

.footer-bottom {
  display: flex;
  overflow: auto;
  padding-bottom: 5rem;
  @apply bg-blue-900;
  @screen md {
    justify-content: center;
    padding-bottom: 0;
    overflow: visible;
  }
  a {
    color: white;
    display: block;
    padding: 1rem;
    white-space: nowrap;
  }
}

.footer-menu {
  a {
    color: white;
    display: block;
    padding: 0.5rem 1rem;
    outline-offset: -2px;
    @apply rounded;
    @media (max-width: theme('screens.md')) {
      padding: 1rem;
      white-space: nowrap;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:after {
        /* @TODO */
        /* @include icon($i-arrow-right); */
        line-height: 1;
        font-size: 1.5em;
      }
    }
  }
  @screen lg {
    @apply font-condensed text-3xl;
  }
}

.footer-contact {
  display: flex;
  justify-content: center;
  > li {
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
}
</style>
